import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Home,
  PageAbonosBucaramanga,
  PagePoliticasDeUso,
  PageTerminosyCondiciones
} from '../pages';
import PagePartidoBucaramanga from '../pages/PagePartidoBucaramanga';
import PagePartidoBucaramanga2 from '../pages/PagePartidoBucaramanga2';
import PageSantanderPartido from '../pages/PageSantanderPartido';
import PageNightRun from '../pages/PageNightRun';
import PageSantanderPartido2 from '../pages/PageSantanderPartido2';
import PageBackToTheMT from '../pages/PageBackToTheMT';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='panaca-viajero' element={<PagePanacaViajero />} />
        <Route path='boleteria-full-80-manizales' element={<PageBoleteriaFull80Manizales />} />
        <Route path='aliados-2' element={<PageAliados />} />
        <Route path='final-liga-betplay-2024-1-bucaramanga-vs-santafe' element={<PageFinalLigaBetplay2024 />} />
        <Route path='futbol-salon' element={<PageFcfc />} />
        <Route path='futbol-salon-2' element={<PageFcfc2 />} />
        <Route path='abonos-bucaramanga-2024-2' element={<PageAbonosBucaramanga />} />
        <Route path='bucaramanga-carabobo' element={<PageBucaramangaCarabobo />} />
        <Route path='carrera-corazon' element={<PageCarreraCorazon />} />
       
        <Route path='bucaramanga-partido' element={<PagePartidoBucaramanga />} />
        <Route path='santander-partido' element={<PageSantanderPartido />} />
        <Route path='santander-partido-2' element={<PageSantanderPartido2 />} />
        <Route path='prisioneros' element={<PagePrisioneros />} />
        <Route path='andina-fest' element={<PageAndinaFest />} />
        <Route path='cantina-decembrina' element={<PageCantinaDecembrina />} />
         <Route path='chicha-brunch' element={<PageChichaBrunch />} />
        <Route path='unlock-the-game' element={<PageUnlockTheGame />} /> */}
        {/* <Route path='remate-cabalgata' element={<PageRemateCabalgata />} />
        <Route path='despechonato' element={<PageDespechonato />} />

        <Route path='la-juerga' element={<PageLaJuerga />} />
        <Route path='festival-vallenato' element={<PageFestivalVallenato />} />
        <Route path='remate-cabalgata-ole' element={<PageRemateCabalgataOle />} />
        */
      }
        <Route path='night-run' element={<PageNightRun />} />
        <Route path='back-to-the-mt' element={<PageBackToTheMT />} />
        <Route path='santander-partido' element={<PageSantanderPartido />} />
        <Route path='santander-partido-2' element={<PageSantanderPartido2 />} />
        {/* <Route path='abonos-bucaramanga-libertadores' element={<PageAbonosBucaramanga />} /> */}
        <Route path='politicas-de-privacidad' element={<PagePoliticasDeUso />} />
        <Route path='terminos-y-condiciones' element={<PageTerminosyCondiciones />} />
        <Route path='bucaramanga-partido' element={<PagePartidoBucaramanga />} />
        <Route path='colo-colo-visitante' element={<PagePartidoBucaramanga2 />} />
         {/* <Route path='abonos-bucaramanga-2025-1' element={<PageAbonosBucaramanga />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
