import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import leftContentImage from '../assets/images/back-to-the-mt-left-image.jpg';
import headerContentImage from '../assets/images/back-to-the-mt-content-header.jpg';
import contentImage from '../assets/images/back-to-the-mt-content.jpg';
import '../styles/PageBackToTheMT.scss';
import { fontawesome } from '../utilities';

const PageBackToTheMT = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='back-to-the-mt-content' className="back-to-the-mt">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerContentImage} alt="BACK TO THE MINI TK  header" title="Imagen header Back to the mini tk " />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="BACK TO THE MINI TK  left content" title="Imagen izquierda Back to the mini tk " />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=nkp3av2ts9iwom2z4ue3eugfgxa5c6&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>

                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>BACK TO THE MINI TK</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="BACK TO THE MINI TK  content" title="Imagen contenido Back to the mini tk " />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageBackToTheMT;